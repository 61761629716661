import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import { graphql, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import classNames from 'classnames';

import 'styles/main.scss';
import './Layout.scss';

import Breadcrumbs from 'common/Breadcrumbs';
import Seo from 'common/Seo';
import StructuredPageSchema from 'common/StructuredPageSchema';
import Alert from 'components/Alert';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Loadable from 'components/Loadable';
import { NL_FORM_STATUS } from 'components/NewsletterPopup/constants';
import WhatsAppWidget from 'components/WhatsAppWidget';
import { isBrowser } from 'utils/browser';
import cookieUtils from 'utils/cookie';
import { schemaService } from 'utils/schemaService';

import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));
const NewsletterPopup = loadable(() => import('components/NewsletterPopup'));

const Layout: FC<LayoutProps> = ({
  children,
  breadcrumbs,
  className,
  seo,
  langProps,
  pagePathname,
  additionalSchema,
  schemaImageUrl,
  footerType,
}) => {
  const {
    allSiteSettings: { nodes: siteSettingsNodes },
    allBrandSettings: { nodes: brandSettingsNodes },
  } = useStaticQuery(graphql`
    {
      allSiteSettings {
        nodes {
          showNewsletterPopup
          lang
          siteUrl
          siteName
        }
      }
      allBrandSettings {
        nodes {
          lang
          brandName
          brandLogo {
            fallbackUrl
          }
          youtubeUrl
          facebookUrl
        }
      }
    }
  `);
  const siteSettings = siteSettingsNodes.find(({ lang }) => langProps.lang === lang) || {};
  const brandSettings = brandSettingsNodes.find(({ lang }) => langProps.lang === lang) || {};

  const schemaData = schemaService.extractSchemaData({
    seo,
    pagePathname,
    siteSettings,
    brandSettings,
    lang: langProps.lang,
    schemaImageUrl,
  });

  const CSSclassNames = classNames('layout', className);
  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));
  const showNewsletterPopup =
    isBrowser() &&
    !cookieUtils.getCookie(NL_FORM_STATUS) &&
    Boolean(parseInt(siteSettings.showNewsletterPopup, 10));

  return (
    <div className={CSSclassNames} data-testid="layout">
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      {seo ? <Seo {...{ seo, langProps, pagePathname }} /> : null}
      <StructuredPageSchema additionalSchema={additionalSchema} schemaData={schemaData} />

      <link rel="preconnect" href={process.env.GATSBY_IMAGE_CLOUD_SERVER} />

      <Header {...{ langProps }} />

      <Container as="main" className="layout__main" fluid="lg">
        <Alert {...{ langProps }} />
        <WhatsAppWidget {...{ langProps }} />

        {breadcrumbs?.length ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}

        {children}
      </Container>
      <Loadable isActive={showNewsletterPopup}>
        <NewsletterPopup actualLang={langProps.lang} />
      </Loadable>
      <Footer {...{ langProps }} footerType={footerType} />
    </div>
  );
};

export default Layout;
